@font-face {
    font-family: "Right Grotesk";
    src:
        url("PPRightGrotesk-WideMedium.woff2") format("woff2"),
        url("PPRightGrotesk-WideMedium.woff") format("woff"),
        url("PPRightGrotesk-WideMedium.eot") format("embedded-opentype"),
        url("PPRightGrotesk-WideMedium.otf") format("opentype"),
        url("PPRightGrotesk-WideMedium.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "Object Sans";
    src:
        url("PPObjectSans-Regular.woff2") format("woff2"),
        url("PPObjectSans-Regular.woff") format("woff"),
        url("PPObjectSans-Regular.eot") format("embedded-opentype"),
        url("PPObjectSans-Regular.otf") format("opentype"),
        url("PPObjectSans-Regular.ttf") format("truetype");
    font-weight: normal;
}
